
/* const m320 = useMediaQuery('(max-width:320px)');
    const m360 = useMediaQuery('(max-width:360px)');
    const m375 = useMediaQuery('(max-width:375px)');
    const m411 = useMediaQuery('(max-width:411px)');
    const m414 = useMediaQuery('(max-width:414px)');
    const m425 = useMediaQuery('(max-width:425px)');
    const m768 = useMediaQuery('(max-width:768px)');
    const m1024 = useMediaQuery('(max-width:1024px)');
    const m1440 = useMediaQuery('(max-width:1440px)');
    const m1600 = useMediaQuery('(max-width:1600px)');
    const m1800 = useMediaQuery('(max-width:1800px)'); */
    @import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
    @media (min-width: 451px) { 
      .div{
        box-sizing: border-box;
      }
      .contenedor_tipos {  
        display: grid;
        grid-template-columns: 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% ;  
        padding: 10px 0px;
      }
     
    .contenedor_simple {  
      display: grid;
      grid-template-rows: 2; 
    }
    .contenedor_principal {  
      display: grid;
      grid-template-rows: auto 1fr auto;
      box-sizing: border-box;
      padding-top: 120px;
    }
    .contenedor_busqueda { 
      display: grid;
      grid-template-rows:  20% 10% 20%;
      justify-items: center;
    }
    .contenedor_busqueda h1{ 
      color: #154360;
      font-family: 'Abril Fatface', cursive;
    }
   
    .imagen_header {
      width: 100%;
      height:400px;
      padding: 0;
      margin: 0;
    }
    .contenedor_principal input { 
      padding: 0;
      margin: 0px 10px;
      height: 50px;
      width: 50%;    
    }
    .texto_imagen{
      position: absolute;
      z-index: 1;
      color: black;
    }
    .contenedor_header_full{
      /* background-color: aqua; */
      width: 100%;
      box-sizing: border-box;
    }
    
    .contenedor_standard {
      box-sizing: border-box;
    }
    .contenedor_tarjetas {
      display: grid;
      grid-template-columns: repeat(1, 1fr);  
      padding: 10px 0px;
      border-bottom:  2px solid #CDD2D4; 
      gap: 1em  
    }
    .contenedor_tarjetas_promociones {
      display: inline-block;
      grid-template-columns: repeat(4, 1fr);  
      padding: 10px 15px;
      border-bottom:  2px solid #CDD2D4; 
      gap: 1em  
    }    
    .contenedor_tarjetas_ciudades {
      display: inline-block;
      grid-template-columns: repeat(4, 1fr);  
      padding: 10px 15px;
      border-bottom:  2px solid #CDD2D4; 
      gap: 1em  
    }
    .contenedor_tarjetas_imagenes {
      width: 100%;
      height: 58%;
      padding: 0;
      margin: 0;
      max-height: 226px;
      display: block;
    }
    .contenedor_tarjetas_imagenes_promociones {
      width: 100%;      
      padding: 0;
      margin: 0;
      max-height: 226px;
      min-height: 226px;
      display: block;      
      max-width: 250px;
      min-width: 250px;
      object-fit: fill;
      cursor: pointer;
    }
    .contenedor_tarjetas_imagenes_ciudades {
      width: 100%;      
      padding: 0;
      margin: 0;
      max-height: 226px;
      min-height: 226px;
      display: block;      
      max-width: 250px;
      min-width: 250px;
      object-fit: fill;
      cursor: pointer;
    }
    .contenedor_header img {
      width: 30%;
      height: 80%;
      padding: 0;
      margin: 0;
    }
    .contenedor_header {
      display: grid;
      grid-template-columns: 30% 70% ; 
      box-sizing: border-box;
      padding: 10px 0px;
      height: 100px;
    }
    .contenedor_header p { 
      padding: 0;
      margin: 0;
      font-size: 14px;
      font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      color: #154360;
      font-weight: bold;
        
    }
    .contenedor_header input { 
      padding: 0;
      margin: 0px 10px;
      height: 30px;
      visibility: hidden;
    }
    .contenedor_header button { 
      padding: 0;
      margin: 0;
      width: 130px;
      height: 35px;
      font-size: 10px;
      color: black;
      text-transform: none;
    }
    .boton{
      background-color: red;
    }
    .contenedor_right { 
      display: grid;
      grid-template-columns: 61% 13% 13% 13%;  
    justify-items: stretch;
    }
  }  
  
@media (max-width: 450px) { 
    .div{
      box-sizing: border-box;
    }
    .contenedor_tipos {  
      display: grid;
      grid-template-columns: 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% ;  
      padding: 10px 0px;
    }
   
  .contenedor_simple {  
    display: grid;
    grid-template-rows: 4; 
  }
  .contenedor_principal {  
    display: grid;
    grid-template-rows: auto auto auto;
    box-sizing: border-box;
    padding-top: 120px;
  }
  .contenedor_busqueda { 
    display: grid;
    grid-template-rows:  20% 10% 20%;
    justify-items: center;
  }
  .contenedor_busqueda h1{ 
    color: #154360;
    font-family: 'Abril Fatface', cursive;
  }
 
  .imagen_header {
    width: 100%;
    height:400px;
    padding: 0;
    margin: 0;
  }
  .contenedor_principal input { 
    padding: 0;
    margin: 0px 10px;
    height: 50px;
    width: 50%;    
  }
  .texto_imagen{
    position: absolute;
    z-index: 1;
    color: black;
  }
  .contenedor_header_full{
    /* background-color: aqua; */
    width: 100%;
    box-sizing: border-box;
    height: 60px;
  }
  
  .contenedor_standard {
    box-sizing: border-box;
  }
  .contenedor_tarjetas {
    display: grid;
    grid-template-columns: repeat(1, 1fr);  
    padding: 10px 0px;
    border-bottom:  2px solid #CDD2D4; 
    gap: 1em;
  }
  .contenedor_tarjetas_promociones {
    padding-top: 10px;
  }
  .contenedor_tarjetas_imagenes {
    width: 100%;
    height: 58%;
    padding: 0;
    margin: 0;
  }
  .contenedor_tarjetas_imagenes_promociones {
    width: 100%;      
    padding: 0;
    margin: 0;
    max-height: 250px;
    min-height: 250px;
    display: block;    
  }
  .contenedor_tarjetas_imagenes_ciudades {
    width: 100%;      
    padding: 0;
    margin: 0;
    max-height: 295px;
    min-height: 295px;
    display: block;    
  }
  .contenedor_header img {
    width: 60%;
    height: 50%;
    padding: 0;
    margin: 0;
  }
 
  .contenedor_header {
    display: grid;
    grid-template-columns: 50% 50% ; 
    box-sizing: border-box;
    padding: 5px 0px;
    height: 50px;
  }
  .contenedor_tipos_prestadores {
    display: grid;
    grid-template-columns: 20% 80% ; 
    box-sizing: border-box;
    padding: 5px 0px;
    height: 50px;
  }
  .contenedor_header p { 
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: #154360;
    font-weight: bold;
      
  }
  .contenedor_header input { 
    padding: 0;
    margin: 0px 10px;
    height: 30px;
    visibility: hidden;
  }
  .contenedor_header button { 
    padding: 0;
    margin: 0;
    width: 130px;
    height: 35px;
    font-size: 10px;
    color: black;
    text-transform: none;
  }
  .boton{
    background-color: red;
  }
  .contenedor_right { 
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;  
  justify-items: stretch;
  padding: 5px 0px; 
  }
}

